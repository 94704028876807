import * as React from 'react';
import { Link, HeadFC } from 'gatsby';
import styled from 'styled-components';
import Seo from '../components/seo';

const PageStyles = styled.main`
  color: '#000';
  padding: '96px';
`;

const HeadingStyles = styled.h1`
  margin-top: 0;
  margin-bottom: 64;
  max-width: 800;
`;

const ParagraphStyles = styled.p`
  margin-bottom: 48;
`;

const CodeStyles = styled.code`
  color: '#8A6534';
  padding: 4;
  background-color: '#FFF4DB';
  font-size: '1.25rem';
  border-radius: 4;
`;

const NotFoundPage = () => (
  <PageStyles>
    <HeadingStyles>Pagina no encontrada</HeadingStyles>
    <ParagraphStyles>
      No pudimos encontrar la pagina que estas buscando.
      <br />
      {process.env.NODE_ENV === 'development' ? (
        <>
          <br />
          Try creating a page in
          {' '}
          <CodeStyles>src/pages/</CodeStyles>
          .
          <br />
        </>
      ) : null}
      <br />
      <Link to="/">Inicio</Link>
      .
    </ParagraphStyles>
  </PageStyles>
);

export default NotFoundPage;

export const Head: HeadFC = () => <Seo title="No encontrada" />;
