/* eslint-disable react/require-default-props */
import * as React from 'react';
import { Script } from 'gatsby';
import useSiteMetadata from '../hooks/use-site-metadata';

const Seo = ({
  title, description, pathname, children,
}: Dto) => {
  const {
    title: defaultTitle,
    description: defaultDescription,
    image,
    siteUrl,
    miniLogo,
  } = useSiteMetadata();

  const complementTitle = title.length < 55 ? `| ${defaultTitle}` : '';

  const seo = {
    title: `${title} ${complementTitle}`,
    description: description || defaultDescription,
    image: `${siteUrl}${image}`,
    url: `${siteUrl}${pathname || ''}`,
    miniLogo: miniLogo.url,
  };

  return (
    <>
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      <link rel="canonical" href={siteUrl} />
      <meta property="og:locale" content="es_ES" />
      <meta property="og:type" content="article" />
      <meta property="og:title" content="nosotros - Electriredes lussan" />
      <meta property="og:url" content={`${siteUrl}/nosotros/`} />
      <meta property="og:site_name" content="Electriredes lussan" />
      <meta property="article:publisher" content="https://www.facebook.com/ELECTRIREDESLUSSAN" />
      <meta property="article:modified_time" content="2022-09-15T23:59:13+00:00" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:url" content={seo.url} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={seo.image} />
      <link rel="icon" href={seo.miniLogo} />
      <Script type="application/ld+json">{'{"@context":"https://schema.org","@graph":[{"@type":"WebPage","@id":"https://www.electriredes.com/nosotros/","url":"https://www.electriredes.com/nosotros/","name":"nosotros - Electriredes lussan","isPartOf":{"@id":"https://www.electriredes.com/#website"},"datePublished":"2018-11-06T21:53:14+00:00","dateModified":"2022-09-15T23:59:13+00:00","breadcrumb":{"@id":"https://www.electriredes.com/nosotros/#breadcrumb"},"inLanguage":"es","potentialAction":[{"@type":"ReadAction","target":["https://www.electriredes.com/nosotros/"]}]},{"@type":"BreadcrumbList","@id":"https://www.electriredes.com/nosotros/#breadcrumb","itemListElement":[{"@type":"ListItem","position":1,"name":"inicio","item":"https://www.electriredes.com/"},{"@type":"ListItem","position":2,"name":"nosotros"}]},{"@type":"WebSite","@id":"https://www.electriredes.com/#website","url":"https://www.electriredes.com/","name":"Electriredes lussan","description":"cableado estructurado, redes electricas, cctv","publisher":{"@id":"https://www.electriredes.com/#organization"},"potentialAction":[{"@type":"SearchAction","target":{"@type":"EntryPoint","urlTemplate":"https://www.electriredes.com/?s={search_term_string}"},"query-input":"required name=search_term_string"}],"inLanguage":"es"},{"@type":"Organization","@id":"https://www.electriredes.com/#organization","name":"Electriredes Lussan SAS","url":"https://www.electriredes.com/","logo":{"@type":"ImageObject","inLanguage":"es","@id":"https://www.electriredes.com/#/schema/logo/image/","url":"https://www.electriredes.com/wp-content/uploads/2018/11/1.png","contentUrl":"https://www.electriredes.com/wp-content/uploads/2018/11/1.png","width":601,"height":153,"caption":"Electriredes Lussan SAS"},"image":{"@id":"https://www.electriredes.com/#/schema/logo/image/"},"sameAs":["https://www.facebook.com/ELECTRIREDESLUSSAN","https://twitter.com/1Electriredes","https://www.instagram.com/electriredeslussan/"]}]}'}</Script>
      <Script type="application/ld+json">{'{"@context": "https://schema.org","@type": "LocalBusiness","address": {"@type": "PostalAddress","addressLocality": "Bogota","addressRegion": "BOG","streetAddress": "Carrera 8 este # 25 - 60 sur","postalCode": "10019","addressCountry": "COL"},"description": "Empresa de servicios en instalacion y/o mantenimiento de cableado estructurado, redes electricas y sistemas de seguridad | Bogota, Colombia.","name": "Electriredes Lussan SAS","telephone": "310 2474432","url": "https://www.electriredes.com","openingHoursSpecification": [{"@type": "OpeningHoursSpecification","dayOfWeek": ["Monday","Tuesday","Wednesday","Thursday","Friday"],"opens": "8:15","closes": "17:45"}]}'}</Script>
      <Script src="https://www.googletagmanager.com/gtag/js?id=G-B94DL51S6T" strategy="idle" />
      <Script id="gtag-config" strategy="idle">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments)};
          gtag('js', new Date());
          gtag('config', 'UA-129431649-1', { page_path: location ? location.pathname + location.search + location.hash : undefined });
          gtag('config', 'AW-789425604', { page_path: location ? location.pathname + location.search + location.hash : undefined });
          gtag('config', 'G-B94DL51S6T', { page_path: location ? location.pathname + location.search + location.hash : undefined });
        `}
      </Script>
      <meta charSet="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
      {children}
    </>
  );
};

export default Seo;

interface Dto {
  title: string,
  description?: string
  pathname?: string
  children?: React.ReactNode
}
