import { graphql, useStaticQuery } from 'gatsby';

const useSiteMetadata = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          image
          siteUrl
        }
      }
      allContentfulMainPage {
        nodes {
          miniLogo {
            url
          }
        }
      }
    }
  `);

  return { ...data.site.siteMetadata, ...data.allContentfulMainPage.nodes[0] };
};

export default useSiteMetadata;
